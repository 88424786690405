import React, { Component } from "react";
import Terminal from "terminal-in-react";
import { FaHeart } from "react-icons/fa";
import ReactGA from "react-ga";

const style = {
  ad: {
    color: "black",
    textDecoration: "none"
  },
  iconColorRed: {
    color: "red"
  }
};

class App extends Component {
  showMsg = () => "Hello World";

  componentDidMount() {
    ReactGA.initialize("UA-135848087-2");
    ReactGA.pageview("/homepage");
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          flexDirection: "column"
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <p style={style.ad}>
            Made with <FaHeart color="red" /> by{" "}
            <a
              href="https://www.simonwikenstahl.se"
              target="_blank"
              style={style.ad}
            >
              Simon Wikenståhl
            </a>
          </p>
        </div>
        <Terminal
          color="green"
          backgroundColor="black"
          barColor="black"
          style={{ fontWeight: "bold", fontSize: "1.5em" }}
          commands={{
            kontakt: (args, print, runCommand) => {
              print(
                "Jag har kontor på Bondegatan 11, Södermalm/Stockholm. För att kontakta mig, gör ni det enklast på: simon@wikenstahl.se eller via telefon: 073 53 99 776. Fler kommandon: ['veta-mer']"
              );
              ReactGA.event({
                category: "Kontakt",
                action: "Read: Kontakt"
              });
            },
            Kontakt: (args, print, runCommand) => {
              print(
                "Jag har kontor på Bondegatan 11, Södermalm/Stockholm. För att kontakta mig, gör ni det enklast på: simon@wikenstahl.se eller via telefon: 073 53 99 776. Fler kommandon: ['veta-mer']"
              );
              ReactGA.event({
                category: "Kontakt",
                action: "Read: Kontakt"
              });
            },
            "veta-mer": (args, print, runCommand) => {
              print(
                "Mitt namn är Simon Wikenståhl och arbetar med utveckling, Hemsidor, Appar, Integrationer, ja det mesta inom IT. Är ni intresserade av att bolla ert projekt med mig, är ni välkomna att kontakta mig på 073 53 99 776 Alt. simon@wikenstahl.se. Vill du veta mer om varför jag har valt att göra en hemsida som denna som min egen? (skriv 'varför'). Fler kommandon: ['kontakt']"
              );
              ReactGA.event({
                category: "Veta-mer",
                action: "Read: Veta-mer"
              });
            },
            "Veta-mer": (args, print, runCommand) => {
              print(
                "Mitt namn är Simon Wikenståhl och arbetar med utveckling, Hemsidor, Appar, Integrationer, ja det mesta inom IT. Är ni intresserade av att bolla ert projekt med mig, är ni välkomna att kontakta mig på 073 53 99 776 Alt. simon@wikenstahl.se. Vill du veta mer om varför jag har valt att göra en hemsida som denna som min egen? (skriv 'varför'). Fler kommandon: ['kontakt']"
              );
              ReactGA.event({
                category: "Veta-mer",
                action: "Read: Veta-mer"
              });
            },
            Varför: (args, print, runCommand) => {
              print(
                "Bra fråga. Denna typen av hemsida skulle inte klassificeras som en 'användarvänlig' hemsida, men däremot sticker den ut. Förhoppningsvis ger den dock en lite personligare känsla? Jag låter dig avgöra det. Låt mig gärna veta vad du tycker, maila mig på simon@wikenstahl.se. Fler kommandon: ['kontakt', 'veta-mer']"
              );
              ReactGA.event({
                category: "Varför denna typ av hemsida",
                action: "Read: Varför denna typ av hemsida"
              });
            },
            varför: (args, print, runCommand) => {
              print(
                "Bra fråga. Denna typen av hemsida skulle inte klassificeras som en 'användarvänlig' hemsida, men däremot sticker den ut. Förhoppningsvis ger den dock en lite personligare känsla? Jag låter dig avgöra det. Låt mig gärna veta vad du tycker, maila mig på simon@wikenstahl.se. Fler kommandon: ['kontakt', 'veta-mer']"
              );
              ReactGA.event({
                category: "Varför denna typ av hemsida",
                action: "Read: Varför denna typ av hemsida"
              });
            },
            popup: () => alert("Terminal in React")
          }}
          descriptions={{
            "open-google": "opens google.com",
            showmsg: "shows a message",
            alert: "alert",
            popup: "alert"
          }}
          msg="Hej och välkommen till simonwikenståhl.se. Jag utvecklar Hemsidor och Appar. För att veta mer kan du skriva in följande kommandon nedan ['kontakt', 'veta-mer']"
        />
      </div>
    );
  }
}

export default App;
